import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Footer from "../components/footer"
import Image from "../components/image"
import SEO from "../components/seo"
import FirstSection from "../components/firstSection"
import SecondSection from "../components/secondSection"
import ThirdSection from "../components/thirdSection"


const IndexPage = () => (
  // <>
  //   <SEO 
  //     title="All posts"
  //     keywords={[`blog`, `gatsby`, `javascript`, `react`]}
  //   />
  //   <Layout/>
  // </>     
  <Layout>
    <SEO 
      title="Home"
      // keywords={[`blog`, `gatsby`, `javascript`, `react`]}
    />
    <FirstSection />
    <SecondSection />
    <ThirdSection/>
  </Layout>
)

export default IndexPage;
